import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const LandingPage = () => {
  useEffect(() => {
    document.title = "Scout - Plan trips with ease";
    window.analytics.page("Landing");
  }, []);

  const auth = useSelector(state => state.firebase.auth);

  if (auth.uid) return <Redirect to="/home" />;

  return (
    <div style={{ backgroundColor: "white" }}>
      <Header />
      <section className="py-6 bg-white">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="text-center">
                <h1 className="display-3 font-weight-bold mb-5">Travel planning, simplified</h1>
                <p className="text-lg">
                  Collect places, build an itinerary, and share with friends.
                  <br />
                  Stay organized with Scout so you can focus on exploring.
                </p>
                <div className="mt-5">
                  <a href="/signup" className="btn btn-primary btn-lg mr-2 mb-4 mb-md-0">
                    Plan a trip
                  </a>
                  <Link to="/explore" className="btn btn-outline-primary btn-lg ml-2">
                    Explore Itineraries
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col">
              <picture>
                <source
                  type="image/webp"
                  alt="Screenshot of app"
                  srcSet="https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Landing%20Page%2Fhome%20screenshot%202-4-20%20webp.webp?alt=media&token=3b34e24b-8024-4be9-bd34-62171c362bdc"
                />
                <img
                  style={{ boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 8px 1px", maxWidth: "100%" }}
                  alt="Screenshot of app"
                  src="https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Landing%20Page%2Fhome%20screenshot%202-4-20.png?alt=media&token=718bed8a-9721-4024-b420-f6ed375e45d7"
                />
              </picture>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col">
              <div className="text-center">
                <p className="mb-3 subtitle">Featured On</p>
                <a
                  href="https://lifehacker.com/build-a-detailed-vacation-itinerary-with-the-scout-site-1840370791"
                  target="_blank"
                  className="mr-2 mr-sm-4 d-inline-block mb-4 mb-sm-0"
                >
                  <img
                    style={{ maxHeight: "50px" }}
                    alt="Lifehacker logo"
                    src="https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Landing%20Page%2FLifehacker%20logo.svg?alt=media&token=ab7f6d40-e333-4237-9d2b-a2f6eeaa03ba"
                  />
                </a>
                <a
                  href="https://www.makeuseof.com/travel-planning-apps-live-maps-trip-itinerary/"
                  target="_blank"
                  className="ml-2 ml-sm-4"
                >
                  <img
                    style={{ maxHeight: "50px" }}
                    alt="Makeuseof logo"
                    src="https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Landing%20Page%2FMakeuseof%20Logo.svg?alt=media&token=feae8910-cb9d-4f06-a1e4-0b634466f7e9"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-6" style={{ backgroundColor: "rgba(152, 165, 249, 0.2)" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 order-lg-1 pb-4">
              <div className="px-5">
                <p className="subtitle text-primary mb-2">Brainstorm a list of places</p>
                <h3>Compile your points of interest with notes for your future self</h3>
              </div>
            </div>
            <div className="col-lg-6 order-lg-2">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Landing%20Page%2Flist_and_notes_screenshot.png?alt=media&token=83121de1-06d4-4538-a0da-dea4a1312c1e"
                alt="Screenshot of notes added to a place"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="py-6">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 order-lg-2 pb-4">
              <div className="px-5">
                <p className="subtitle text-primary mb-2">Connect the dots</p>
                <h3>See all your places on a map and plan an efficient itinerary</h3>
              </div>
            </div>
            <div className="col-lg-6 order-lg-1">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Landing%20Page%2Froute_screenshot.png?alt=media&token=552835ad-b0a2-4831-b9b3-bfb2b7ef50f2"
                alt="Screenshot of map next to the itinerary"
                style={{ boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 8px 1px", maxWidth: "100%" }}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="py-6" style={{ backgroundColor: "rgba(152, 165, 249, 0.2)" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 order-lg-1 pb-4">
              <div className="px-5">
                <p className="subtitle text-primary mb-2">Plan better, together</p>
                <h3>
                  Collaborate in real time — share a link to your trip and anyone can jump in to add
                  places
                </h3>
              </div>
            </div>
            <div className="col-lg-6 order-lg-2">
              <picture>
                <source
                  type="image/webp"
                  alt="Screenshot of a list of places"
                  srcSet="https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Landing%20Page%2Fcollab_screenshot%20webp.webp?alt=media&token=63d3a2aa-ac3d-43e0-9f64-403b691d0fe4"
                />
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/Landing%20Page%2Fcollab_screenshot.png?alt=media&token=030b3f4d-34ea-49a4-9365-6825efc06518"
                  alt="Screenshot of a list of places"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 8px 1px",
                    maxWidth: "100%",
                  }}
                />
              </picture>
            </div>
          </div>
        </div>
      </section>

      <section className="py-6" id="sample_trips">
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-8">
              <p className="subtitle text-secondary">Stay and eat like a local</p>
              <h2>Our favorite Scout trips</h2>
            </div>
            <div className="col-md-4 d-lg-flex align-items-center justify-content-end">
              <Link to="/explore" className="text-muted text-sm">
                See more trips
                <i className="fas fa-angle-double-right ml-2" />
              </Link>
            </div>
          </div>
          <div
            className="row d-flex justify-content-between"
            style={{ overflowX: "auto", flexWrap: "nowrap", WebkitOverflowScrolling: "touch" }}
          >
            <div
              className="px-2 h-auto"
              style={{ width: "215px", minWidth: "215px", marginRight: "15px" }}
            >
              <div className="card card-poster gradient-overlay mb-4 mb-lg-0">
                <a
                  href="https://scoutexplore.com/t/UzkC8syBuDesSAjHFBmX/public"
                  className="tile-link"
                />
                <img
                  src="https://images.unsplash.com/photo-1448317971280-6c74e016e49c?q=80&w=500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  alt="Empire state building"
                  className="bg-image"
                />
                <div className="card-body overlay-content">
                  <h6 className="card-title text-shadow text-uppercase">New York</h6>
                  <p className="card-text text-sm">The Big Apple</p>
                </div>
              </div>
            </div>
            <div
              className="px-2 h-auto"
              style={{ width: "215px", minWidth: "215px", marginRight: "15px" }}
            >
              <div className="card card-poster gradient-overlay mb-4 mb-lg-0">
                <a
                  href="https://scoutexplore.com/t/HIzVSl0Qw28vMVFf9oj5/public"
                  className="tile-link"
                />
                <img
                  src="https://images.unsplash.com/photo-1522547902298-51566e4fb383?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80"
                  alt="Japanese temple"
                  className="bg-image"
                />
                <div className="card-body overlay-content">
                  <h6 className="card-title text-shadow text-uppercase">Japan</h6>
                  <p className="card-text text-sm">Sushi, temples, shopping</p>
                </div>
              </div>
            </div>
            <div
              className="px-2 h-auto"
              style={{ width: "215px", minWidth: "215px", marginRight: "15px" }}
            >
              <div className="card card-poster gradient-overlay mb-4 mb-lg-0">
                <a
                  href="https://scoutexplore.com/t/XtTi623rE11nCoIKoEDV/public"
                  className="tile-link"
                />
                <img
                  src="https://images.unsplash.com/photo-1519275565142-d81952f9e2d1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80"
                  alt="Taipei 101"
                  className="bg-image"
                />
                <div className="card-body overlay-content">
                  <h6 className="card-title text-shadow text-uppercase">Taipei</h6>
                  <p className="card-text text-sm">Night markets, boba, shaved ice, more food</p>
                </div>
              </div>
            </div>
            <div
              className="px-2 h-auto"
              style={{ width: "215px", minWidth: "215px", marginRight: "15px" }}
            >
              <div className="card card-poster gradient-overlay mb-4 mb-lg-0">
                <a
                  href="https://scoutexplore.com/t/9IM4JMmJUBJrlagBtnjz/public"
                  className="tile-link"
                />
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/scout-test-c0176.appspot.com/o/portland.jpg?alt=media&token=a9df9a22-494b-4e80-9f4b-f656e3682ef8"
                  alt="Portland"
                  className="bg-image"
                />
                <div className="card-body overlay-content">
                  <h6 className="card-title text-shadow text-uppercase">Portland</h6>
                  <p className="card-text text-sm">Great food, nature, breweries</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-6" style={{ backgroundColor: "rgba(152, 165, 249, 0.2)" }}>
        <div className="container">
          <div className="text-center">
            <h1 className="mb-2">Adventure awaits</h1>
            <p className="mb-4 text-muted">Don't let the planning hold you up</p>
            <a href="/signup">
              <button className="btn btn-primary btn-lg">Plan a trip</button>
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default LandingPage;
